import React from 'react';

import aboutImage1 from 'assets/images/pages/overons_wiezijnwij/1.jpg';
import aboutImage2 from 'assets/images/pages/overons_wiezijnwij/2.jpg';
import aboutImage3 from 'assets/images/pages/overons_wiezijnwij/3.jpg';
import aboutImage4 from 'assets/images/pages/overons_wiezijnwij/4.jpg';
import magentoImage from 'assets/images/magento-certifications.png';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import Content from 'blocks/Content';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import Quote from 'components/Quote';
import Img from 'components/Image';

const OverOns = () => (
  <Layout>
    <Seo image={{ src: aboutImage1 }} />

    <Title
      image={{ src: aboutImage1 }}
      alt="Batao, geen gewone webshop bouwer"
      subtitle="Jouw website een online succes"
      title="Batao, geen gewone webshop bouwer"
    >
      E-commerce platformen op maat sinds 2007. Ieder project op basis van de
      bedrijfscultuur en doelstellingen van onze opdrachtgevers.
    </Title>

    <Section>
      <Image image={{ src: aboutImage2 }} alt="Wij vinden de oplossing">
        <Heading size="xl" as="h2">
          Wij vinden de oplossing
        </Heading>

        <Text spacing="sm">
          Ben je op zoek naar een generieke, dertien in een dozijn webshop
          bouwer? Zoek dan verder. Vind je persoonlijke aandacht en een goede
          klantrelatie net zo belangrijk als wij? Dan kunnen we je vast helpen!
          Wij adviseren, maar vinden luisteren minstens zo belangrijk. Wij
          bouwen de oplossing die past bij jouw bedrijf, bedrijfscultuur en
          doelstellingen.
        </Text>

        <Text>
          Voordat wij met elkaar in zee gaan willen we jou en jouw bedrijf dan
          ook eerst goed leren kennen. Vervolgens gaan we voor je aan de slag,
          waarbij we ons zoveel mogelijk onderdeel van jouw bedrijf willen laten
          voelen, zodat we de kernwaarden van jouw bedrijf kunnen ‘ademen’ en
          optimaal kunnen uitstralen op jouw ecommerce platform.
        </Text>
      </Image>
    </Section>

    <Section>
      <Content wide>
        <Quote author="Remco de Jong" position="Co Founder Batao">
          “Wij zijn een team van gerenommeerde professionals, toegewijd aan het
          verlenen van de beste service en opleveren van succesvolle e-commerce
          projecten.”
        </Quote>
      </Content>
    </Section>

    <Section>
      <Content>
        <Heading size="xl" as="h2">
          Batao, de gekwalificeerde Magento webshop bouwer
        </Heading>

        <Text spacing="md">
          Al onze ontwikkelaars zijn Magento gecertificeerd. Wij vinden
          persoonlijke ontwikkeling daarnaast ontzettend belangrijk en staan
          erop dat al onze medewerkers zich blijven scholen.
        </Text>

        <Img
          image={{ src: magentoImage }}
          alt="Magento certifications"
          style={{ maxWidth: 400, width: '100%', margin: '0 auto' }}
        />
      </Content>
    </Section>

    <Section>
      <Image
        image={{ src: aboutImage3 }}
        alt="Het verhaal van Batao"
        position="right"
      >
        <Heading size="xl" as="h2">
          Het verhaal van Batao
        </Heading>

        <Text spacing="sm">
          Wij komen uit Nijmegen, en daar zijn we trots op. Midden in de regio
          die vroeger toebehoorde aan de dappere Batavieren, staat ons team
          paraat om vriend en vijand te verrassen met dezelfde commitment en
          discipline: wij geven alles om de beste E-commerce platformen en
          webshops op te leveren.
        </Text>

        <Text spacing="lg">
          We zien onszelf graag als een kleine stam van moderne krijgers, klaar
          om onze klanten te helpen de online competitie te verslaan of nieuwe
          technische uitdagingen te overwinnen.
        </Text>

        <Button color="tertiary" to="/over-ons/naam-en-logo">
          Uitleg over ons logo en naam
        </Button>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: aboutImage4 }}
        alt="Batao: ook een succesvol B2B module ontwikkelaar"
      >
        <Heading size="xl" as="h2">
          Batao: ook een succesvol B2B module ontwikkelaar
        </Heading>

        <Text>
          Wij zijn méér dan alleen een webshop bouwer. Zo maken we ook geweldige
          B2B Magento-modules onder de naam Cart2Quote. Zo zijn we sinds 2009 de
          leverancier van een module waarmee Magento wordt uitgebreid met een
          zakelijke offerte functionaliteit. Deze module wordt wereldwijd
          ingezet door meer dan 15.000 Magento webshopeigenaren.
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default OverOns;
